* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

@font-face {
  src: url(../fonts/AlfaSlabOne-Regular.ttf);
  font-family: "AlfaSlabOne-Regular";
}

@font-face {
  src: url(../fonts/Capriola-Regular.ttf);
  font-family: "Capriola-Regular";
}

@font-face {
  src: url(../fonts/Inter-Regular.ttf);
  font-family: "Inter-Regular";
}

@font-face {
  src: url(../fonts/Inter-SemiBold.ttf);
  font-family: "Inter-SemiBold";
}

@font-face {
  src: url(../fonts/Inter-Medium.ttf);
  font-family: "Inter-Medium";
}

body {
  font-size: 16px;
}

/* common css */

:root {
  --white-color: #ffffff;
  --yellow-color: #eed65b;
  --black-color: #000;
  --light-blue-color: #039BE5;
  --red-color: #FF0000;
  --green-color: #4CAF50;
  --blue: #1877F2;
  --grey-9A9EAC: #9A9EAC;
  --dark-bg-color: #1F2128;
  --orange-E67A30: #49DADA;


}

.red {
  background: var(--red-color);
}

.blue {
  background: var(--blue);
}

.light-blue {
  background: var(--light-blue-color);
}

.green {
  background: var(--green-color);
}

.insta-bg {
  background: url('../images/insta-bg.png');
  background-size: cover;
  background-position: center;
}

a {
  display: inline-block;
  text-decoration: none;
}

img,
video {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  text-transform: unset;
  margin-bottom: 15px;
  line-height: 1;
  text-transform: uppercase;
}

.container,
.container-fluid {
  max-width: 100%;
  margin: 0 auto;
}

/* landing css */

#landing .landing-box {
  background: var(--black-color);
  overflow: hidden;
  min-height: 100vh;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 480px;
  margin: 0 auto;
  position: relative;
}

/* landing-box-top **********/
.landing-box-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#landing .logo-box {
  text-align: left;
}

#landing .logo-box img {
  max-width: 45%;
  width: 100%;
}

.share-box {
  text-align: right;
}

.share-box img {
  max-width: 50%;
}
.social-share {
  justify-content: space-evenly;
  display: flex;
}

/* landing-box-middle **********/

.landing-box-middle {
  width: 100%;
  margin: 30px 0 0px 0;
}


.landing-box-middle .item img {
  border-radius: 20px;
  width: 100%;

}

/* landing-box-bottom ***********/
.landing-box-bottom .text-box {
  text-align: center;
  color: white;
  font-family: "AlfaSlabOne-Regular";
  font-weight: 400;
  margin: 0 0 10px 0;
}

.landing-box-bottom .text-box p {
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.landing-box-bottom .text-box p.first {
  text-transform: capitalize;
}

.landing-box-bottom .text-box p span {
  font-size: 46px;
}

.landing-box-bottom .button-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.landing-box-bottom .button-box .box {
  width: 49%;
}

.landing-box-bottom .button-box .box ul {
  margin: 0;
  padding: 0;
}

.button-box .common-btn {
  display: flex;
  align-items: center;
  border-radius: 50px;
  /* justify-content: center; */
  font-family: "Capriola-Regular";
  padding:12px 5px;
  position: relative;
}

.box ul li {
  margin-bottom: 10px;
  list-style: none;
}

.owl-theme .owl-dots .owl-dot span {
  height: 7px;
  width: 7px;
  background: rgba(255, 255, 255, 0.37);
}

.owl-theme .owl-dots .owl-dot.active span {
  width: 30px;
  background: var(--white-color);
  margin: 5px 3px;
}

/* Modal css  */
#login-modal .login-box .card .logo-box {
  margin-bottom: 20px;
}

#login-modal .login-box .card .logo-box img {
  width: 100%;
  max-width: 50%;
}

.login-box .card {
  background-color: var(--black-color);
  padding: 20px 14px;
  border-radius: 0;
  border: 1px solid var(--grey-9A9EAC);
}

.login-box .card .card-title h3 {
  color: var(--white-color);
  font-weight: 500;
  font-family: "Inter-SemiBold";
  font-size: 18px;
}

.login-box .card .card-title p {
  font-size: 16px;
  font-family: "Inter-Regular";
  color: var(--grey-9A9EAC);
  margin-bottom: 0;
}

.login-box .card .card-body {
  padding: 0;
}

#login-modal .login-box .form-group {
  margin-bottom: 15px;
}

#login-modal .login-box .card .form-label {
  color: var(--grey-9A9EAC);
  font-size: 12px;
}

#login-modal .login-box .card .form-control {
  background-color: var(--dark-bg-color);
  border-color: #333844;
  color: var(--grey-9A9EAC);
  border-radius: 8px;
  padding: 6px 10px;
}

#login-modal .login-box .form-group .toggle-password {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -30px;
  color: var(--grey-9A9EAC);
}

#login-modal .login-box .card .form-control:hover,
#login-modal .login-box .card .form-control:focus {
  box-shadow: none;
}

.login-box .card .card-body .btn {
  background-color: var(--orange-E67A30);
  color: var(--black);
  font-weight: 500;
  border-radius: 8px;
  padding: 6px 12px;
  font-family: "Inter-SemiBold";
  width: 100%;
  margin-top: 10px;
}

p.s-space {
  line-height: 12px;
  margin-bottom: 0;
}

.landing-box-bottom .text-box {
  color: #fff;
  font-family: AlfaSlabOne-Regular;
  font-weight: 400;
  margin: 0 0 10px;
  text-align: center;
  padding: 0 30px;
}

.landing-box-bottom .text-box p strong {
  font-size: 35px;
}

.landing-box-bottom .text-box p {
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

/* .swiper-pagination-bullet-active{
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: rgb(216, 12, 12) !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 50px !important; 
} */
.swiper-pagination {
  position: static !important;
}
.swiper-pagination-bullet-active {
  background: white !important;
  height: 8px !important;
  width: 20px !important;
  border-radius: 50px !important; 
}

.swiper-pagination-bullet {
  background: white !important;
  opacity: 1 !important;
}

.swiper-slide .item {
  width: 85%;
  margin: 0 auto;
}


/* responsive */

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 430px;
    margin: 1.75rem auto;
  }
}

@media(max-width:393px) {
  .landing-box-middle {
    margin: 10px 0;
  }
}

@media (max-width: 375px) {


.landing-box-middle .item {
  height: 280px;
}
.landing-box-middle .item img {
  width: 100%;
  height: 100%;
}
  .landing-box-bottom .text-box p span {
    font-size: 34px;
  }

  .landing-box-bottom .text-box p {
    font-size: 14px;
    margin-bottom: 14px;
  }
  .button-box .common-btn span {
    font-size: 10px;
  }

}

@media (max-width: 320px) {
  .landing-box-middle .item {
    height: 190px;
  }
  .landing-box-middle {
    margin: 10px 0 0;
}
.landing-box-bottom .text-box p {
  font-size: 12px;
  margin-bottom: 8px;
}
.landing-box-bottom .text-box p strong {
  font-size: 27px;
}
.landing-box-bottom .text-box p span {
  font-size: 26px;
}
.box ul li {
  margin-bottom: 5px;
  list-style: none;
}
}